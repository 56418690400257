<template>
  <div
    :id="NAV_ID"
    ref="navBarRef"
    :class="[
      'home-e-nav-bar',
      { 'nav-shadow': showNavShadow },
      showHidenMenu ? 'nav-bar_active' : '',
      // showNavBarAlert ? 'nav-bar-header_tip' : '',
      isDarkNavTheme ? 'dark-nav' : ''
    ]"
    :style="navBarStyle"
  >
    <PagesNewHomeCommonContainer class="nav-bar-container">
      <div class="nav-bar-left-logo-1" @click.native="goLogoHomePage">
        <!-- <img class="logo-img" src="@/assets/images/logo-1.png" alt="" /> -->
        <!-- <EvotoSvg class="logo-img" /> -->
        <div class="logo-img">
          <EvotoIconSvg />
          <EvotoTextSvg :class="['logo-text', { 'logo-transparent': hideLogoText }]" />
        </div>
      </div>
      <div class="nav-bar-center-link">
        <div class="link-content">
          <CommonNavBarMenuList :nav-list="navLink" @link-item-click="linkItemClick">
            <template #payment>
              <div v-if="link_open || hallows_open" class="hot-flag">
                <img :src="getCloudAssets('/images/common/hot-3.png')" alt="AI photo editor" />
              </div>
            </template>
          </CommonNavBarMenuList>
        </div>

        <!-- 国际化切换 -->
        <CommonTopSwitchLanguage v-show="showNavbarLanguage" />

        <div v-if="!use_user.isLogin" class="login-in" @click="goLoginPage">
          {{ useTranslateI18n("login.signIn.title") }}
          <!-- <CommonAnimationScrollText>{{ useTranslateI18n("login.signIn.title") }}</CommonAnimationScrollText> -->
        </div>
        <div v-if="use_user.isLogin" class="login-avatar">
          <ClientOnly>
            <el-dropdown
              popper-class="nav-drop-down_zz"
              trigger="hover"
              placement="bottom-end"
              :show-timeout="100"
              @visible-change="avatarInfoChange"
            >
              <template #dropdown>
                <el-dropdown-menu>
                  <div class="dropdown-detail">
                    <div class="top-avatar">
                      <span class="top-avatar-img">{{ use_user.nicknameLetter }}</span>
                      <div class="top-avatar-d">
                        <span class="top-avatar-nickName">{{ use_user.nickname }}</span>
                        <span class="top-avatar-email">{{ use_user.userEmail }}</span>
                      </div>
                    </div>
                    <div v-if="!use_user.isBadUser" class="credits">
                      <div class="credits-i">
                        <div class="credits-i-left-c">
                          <LayersIcon />
                        </div>
                        <div
                          :class="[`credits-i-right-i`, !use_user.isChildUser ? 'credits-i-right-i_cursor' : ``]"
                          @click="goBilling"
                        >
                          <span>{{ useTranslateI18n(`profile.cre`) }}</span>
                          <el-icon v-if="!use_user.isChildUser"><ArrowRight /></el-icon>
                        </div>
                      </div>
                      <div class="credits-t">
                        <!-- <span class="c-used-count">{{ subscription_info.used_credits }}</span>
                      <span>of</span>
                      <span class="c-total-count">{{ subscription_info.total_credits }}</span>
                      <span>used</span> -->
                        <div class="t-remaining">
                          <span class="title">{{
                            useTranslateI18n(`profile.billingUsage.billing.cardData[0].re`)
                          }}</span>
                          <span class="count">{{
                            subscription_info.total_credits - subscription_info.used_credits
                          }}</span>
                        </div>
                        <div class="t-total">
                          <span class="title">{{
                            useTranslateI18n(`profile.billingUsage.billing.cardData[0].to`)
                          }}</span>
                          <span class="count">{{ subscription_info.total_credits }}</span>
                        </div>
                      </div>
                      <!-- <div v-if="use_user.isChildUser" class="credits-t-t">
                      <div class="used-content">
                        <span class="c">{{ subscription_info.used_credits }}</span>
                        <span class="u">{{ useTranslateI18n(`profile.used`) }}</span>
                      </div>
                    </div> -->
                      <div v-if="subscription_info.next_bill_date" class="credits-d">
                        {{
                          useTranslateI18n(`profile.billingUsage.sub.normalDesc`, [
                            subscription_info.next_get_credits,
                            subscription_info.next_bill_date
                          ])
                        }}
                      </div>
                    </div>
                    <div :class="['menu-item', use_user.isBadUser ? 'menu-item_mar' : '']">
                      <div
                        class="menu-item_i"
                        @click="
                          () => {
                            goProfile(TabListValue.BASIC_INFO)
                          }
                        "
                      >
                        <ProfileIcon />
                        <div class="menu-item_c">
                          <span> {{ useTranslateI18n("common.navBarMenu[0]") }}</span>
                        </div>
                      </div>
                      <!-- 商家展示 -->
                      <div
                        v-if="use_user.isBusinessUser || use_user.isChildBusinessUser"
                        class="menu-item_i"
                        @click="goBusinessAdmin"
                      >
                        <BaseIcon />
                        <div class="menu-item_c">
                          <span>{{ useTranslateI18n("common.navBarMenu[2]") }}</span>
                          <el-icon><ArrowRight /></el-icon>
                        </div>
                      </div>

                      <!-- <div
                      class="menu-item_i"
                      @click="
                        () => {
                          goProfile(TabListValue.INVITATION)
                        }
                      "
                    >
                      <InvitationSvg />
                      <div class="menu-item_c">
                        <span>{{ useTranslateI18n("common.navBarMenu[3]") }}</span>
                      </div>
                    </div> -->

                      <div class="menu-item_i" @click="logOutClick">
                        <SignOutIcon />
                        <div class="menu-item_c">
                          <span>{{ useTranslateI18n("common.navBarMenu[1]") }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-dropdown-menu>
              </template>
              <span class="avatar-name">
                <span class="avatar-name-c">
                  {{ use_user.nicknameLetter }}
                </span>
              </span>
            </el-dropdown>
          </ClientOnly>
        </div>
        <div class="nav-bar-right-button" @click="showHidenMenuHandler">
          <span></span>
        </div>
      </div>
    </PagesNewHomeCommonContainer>
    <ClientOnly>
      <div class="hide-menu-list">
        <div class="hide-menu-list-container">
          <CommonNavBarMenuHiddenList :nav-list="navLink" @link-item-click="linkItemClick" />
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { navbarLink, deviceDict, NAV_ID, INavbarTheme } from "@/constant/navbar"
import { useUser } from "@/store/user"
import { INavBarLinkData, navBarLinkKey, INavBarLinkObj } from "@/types/components/common/navbar"
import LayersIcon from "@/assets/icons/common/layers-2.svg"
import ProfileIcon from "@/assets/icons/common/profile.svg"
import SignOutIcon from "@/assets/icons/common/signOut.svg"
import BaseIcon from "@/assets/icons/common/base-icon.svg"
import { ArrowRight } from "@element-plus/icons-vue"
import { IOfficialPricingSource } from "@/composables/utils/gtag/payment"
import {
  IOfficialSignInOperate,
  IOfficialSignInPosition,
  pointSignInPositionDict
} from "@/composables/utils/gtag/login"
import { TabListValue } from "@/constant/pages/profile"
import { PROFILE_PAGE_PATH, PAYMENT_PAGE_PATH, HOME, DOWNLOAD_PAGE_PATH, BLOG_PAGE } from "@/constant/route"
import { businessAdminUrl } from "@/constant/route"
import { CSSProperties } from "vue"
import { SessStorageKey } from "~~/constant/cache"
import { ILinkButtonName } from "@/composables/utils/mp/common"
import { useTranslateI18n } from "~~/composables/store/i18n-country"
// import EvotoSvg from "@/assets/icons/common/nav-evoto.svg"
import EvotoIconSvg from "@/assets/icons/common/logo-icon.svg"
import EvotoTextSvg from "@/assets/icons/common/logo-text.svg"

import { getCloudAssets } from "~~/utils"
import { useAllActivitys } from "@/constant/useAllActivitys"
import { FORUM_ADDRESS } from "~/constant/config"

const porps = defineProps({
  isReload: {
    type: Boolean,
    default: true
  }
})

const proxy = useProxy()
const use_user = useUser()
const navLink = ref<INavBarLinkData>(navbarLink())
let navBarBg = useNavBarBg()
let { usePaymentGTag, useLoginGTag } = useGTag()
let { _PointOfficePricing, getOfficialSignStatusParams, getOfficialPaymentStatus } = usePaymentGTag()
let { _PointOfficialSignIn } = useLoginGTag()

let { useCommonMp } = useMp()
let { _pointOfficialPageButtonClick, _pointOfficialSignInButtonClick } = useCommonMp()

let showNavbarLanguage = computed(() => {
  return useNavBarLanguage().value
})

const { link_open } = useLandingHotActivity()

const { hallows_open } = useAllActivitys()

const navBarRef = ref()

const showHidenMenu = ref(false)

const background = ref("transparent")
const navBarStyle = computed<CSSProperties>(() => {
  return {
    background: background.value
    // transform: showNavBarAlert.value ? `translate3d(0, ${transformY.value}px, 0)` : "unset"
  }
})

watch(
  () => showHidenMenu.value,
  (newValue) => {
    if (process.client) {
      document.body.style.overflow = newValue ? "hidden" : "auto"
    }
  }
)

watch(
  () => useNavbarTheme().value,
  () => {
    setTimeout(() => {
      scrollFn()
    })
  }
)

const subscription_info = computed(() => {
  return use_user.subscription_info
})

let sortClientWidth = []
for (let clientWidth of Object.keys(deviceDict)) {
  sortClientWidth.push(Number(clientWidth))
}

const emit = defineEmits(["loginButtonClick", "getLayoutStyle"])

const { _pointNewHomeNavToDownloadClick, _pointNewHomeSignUpClick } = useNewHomeTsSdk()

const goLoginPage = () => {
  let path = useRoute().path
  _pointNewHomeSignUpClick()
  _pointOfficialPageButtonClick({
    pageTitle: useMpCommonPageTitle(),
    path: useMpCommonPath(),
    featureName: "clickWebsiteNavigationBar",
    featureModule: `websiteButtonClick`,
    buttonName: "navigationSignin"
  })
  _pointOfficialSignInButtonClick({
    subPageTitle: "signInInfoInputPage",
    buttonName: "clickSingIn"
  })
  _PointOfficialSignIn({
    official_operate: IOfficialSignInOperate.click_signin,
    official_position: path.startsWith("/features")
      ? IOfficialSignInPosition.signin_features
      : pointSignInPositionDict()[path]
  })
  emit("loginButtonClick")
}

const goLogoHomePage = () => {
  navigateTo(unref(HOME))
  _pointOfficialPageButtonClick({
    pageTitle: useMpCommonPageTitle(),
    path: useMpCommonPath(),
    featureName: "clickWebsiteNavigationBar",
    featureModule: `websiteButtonClick`,
    buttonName: "navigationHomeButton"
  })
}

const logOutClick = () => {
  use_user.clearUserInfo(porps.isReload)
}

const goProfile = (target?: number) => {
  navigateTo(`${unref(PROFILE_PAGE_PATH)}?t=${target}`)
}

const goBilling = () => {
  if (!use_user.isChildUser) {
    navigateTo(`${unref(PROFILE_PAGE_PATH)}?t=${TabListValue.MY_BILLING}`)
  }
}

// 进入商家管理后台
const goBusinessAdmin = () => {
  const { isIOS } = useDevice()
  unref(isIOS) ? (location.href = businessAdminUrl) : window.open(businessAdminUrl, "_blank")
}

// link 点击
const linkItemClick = (item: INavBarLinkObj) => {
  showHidenMenu.value = false
  let key = item.key
  _pointOfficialPageButtonClick({
    pageTitle: useMpCommonPageTitle(),
    path: useMpCommonPath(),
    featureName: "clickWebsiteNavigationBar",
    featureModule: `websiteButtonClick`,
    buttonName: ILinkButtonName()[item.path]
  })
  switch (key) {
    case navBarLinkKey.Pricing:
      // eslint-disable-next-line no-case-declarations
      if (useRoute().path !== unref(PAYMENT_PAGE_PATH)) {
        _PointOfficePricing({
          official_sigin_status: getOfficialSignStatusParams(),
          official_source: IOfficialPricingSource.click_pricing_tab,
          official_payment_status: getOfficialPaymentStatus()
        })
      }
      navigateTo(unref(PAYMENT_PAGE_PATH))
      return
    case navBarLinkKey.Download:
      // eslint-disable-next-line no-case-declarations
      if (useRoute().path !== unref(DOWNLOAD_PAGE_PATH)) {
        _pointNewHomeNavToDownloadClick()
      }
      return
    case navBarLinkKey.Blog:
      location.href = unref(BLOG_PAGE)
      return
    case navBarLinkKey.Forum:
      useLocationOpen(FORUM_ADDRESS)
      return
    default:
      break
  }
}

const showHidenMenuHandler = () => {
  showHidenMenu.value = !showHidenMenu.value
}

const avatarInfoChange = async (show) => {
  if (show) {
    _pointOfficialPageButtonClick({
      pageTitle: useMpCommonPageTitle(),
      path: useMpCommonPath(),
      featureName: "clickWebsiteNavigationBar",
      featureModule: `websiteButtonClick`,
      buttonName: "navigationAvatarButton"
    })
    await use_user.setAllUserInfo()
  }
}

function getDevice() {
  if (process.client) {
    let clientWidth = document.documentElement.offsetWidth
    // 关闭侧拉菜单 此时是显示侧拉按钮的临界
    if (clientWidth >= 1006) {
      showHidenMenu.value = false
    }
    scrollFn()
  }
}
const topOffset = ref(0)
const transformY = ref(0)
const showNavBarAlert = useShowNavBarAlert()
watch(showNavBarAlert, (v) => {
  if (!v) {
    transformY.value = 0
    emit("getLayoutStyle", { transform: `transLate(0, ${transformY.value}px)` })
  }
})

const isDarkNavTheme = computed(() => {
  return useNavbarTheme().value === INavbarTheme.Dark
})

const showNavShadow = useNavBarShadowShow()

const hideLogoText = ref(false)

function scrollFn() {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset

  let during = 0

  // 有首屏的时候设置nav主题
  if (!useFixedNavbarTheme().value) {
    // 有首屏的时候滑出首屏再变色
    if (scrollTop > during) {
      background.value = navBarBg.value
      hideLogoText.value = true
    } else {
      background.value = "transparent"
      hideLogoText.value = false
    }
  } else {
    background.value = unref(useNavbarCustomBg())
  }

  if (showNavBarAlert.value) {
    transformY.value = topOffset.value - scrollTop >= 0 ? topOffset.value - scrollTop : 0
    emit("getLayoutStyle", { transform: `transLate(0, ${transformY.value}px)` })
  }
  proxy.$forceUpdate()
}

let scrollEvent
let resizeEvent
function initScrollStyle() {
  if (process.client) {
    scrollEvent = useEventListener(window, "scroll", scrollFn)
    resizeEvent = useEventListener(window, "resize", getDevice)
  }
}

// 判断首页是否显示 头部警告tip
const hideAnnoFlag = process.client && Boolean(getLocalStorage(SessStorageKey.HIDE_ANNO_FLAG, "sessionStorage"))
async function initShowHeaderTip() {
  if (process.client) {
    // const country = await getClientCountry()
    // 如果是越南ip的用户展示新套餐界面
    // if (country === ICountryDict.VN) {
    // showHeaderTip.value = true
    let data = await getNoticeValues()
    if (data.state) {
      useNavBarAlertConfig().value = {
        btn_style: data.btn_style,
        btn_text: data.btn_text,
        btn_url: data.btn_url,
        icon: data.icon,
        element: data.element,
        content: data.content
      }
      showNavBarAlert.value = !hideAnnoFlag
      await getAnnoHeight()
    }
    // tipAlertInstance = useHeaderTipAlert(useTranslateI18n(`common.headerTip`))
    // }
    // tipAlertInstance = useHeaderTipAlert(useTranslateI18n(`common.headerTip`))
    // showHeaderTip.value = true
  }
}

function findDom(target: ParentNode, tagName: string) {
  if (target.nodeName && target.nodeName === tagName) {
    return true
  } else if (target.parentNode) {
    return findDom(target.parentNode, tagName)
  } else {
    return false
  }
}

async function getAnnoHeight() {
  await nextTick()
  const annoLinkPoint = document.querySelector(".e-anno") as HTMLElement
  if (annoLinkPoint) {
    useEventListener(annoLinkPoint, "click", (e) => {
      let targetDom = e.target as HTMLElement
      // 链接点击埋点
      if (findDom(targetDom, "A")) {
        _pointOfficialPageButtonClick({
          pageTitle: useMpCommonPageTitle(),
          path: useMpCommonPath(),
          featureName: "clickWebsiteNavigationBar",
          featureModule: `websiteButtonClick`,
          buttonName: `noticeBoard`
        })
      }
    })
  }
  const { height } = useElementBounding(document.querySelector(".e-anno") as HTMLElement)
  watch(
    height,
    (v) => {
      transformY.value = topOffset.value = v
      emit("getLayoutStyle", { transform: `transLate(0, ${transformY.value}px)` })
    },
    { immediate: true }
  )
}

onBeforeUnmount(() => {
  // 清除事件
  scrollEvent()
  resizeEvent()
})

defineExpose({
  initShowHeaderTip
})

onMounted(async () => {
  initScrollStyle()
  initShowHeaderTip()
  scrollFn()
})
</script>

<!-- 取消scoped 打包后才能显示完整navbar -->
<style scoped lang="scss">
.home-e-nav-bar {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  // @include ease-in-out;

  &.nav-bar-header_tip {
    transition: unset;
    transform-style: preserve-3d;
    transform: translate3d(0, 42px, 0);
    @media (max-width: 1024px) {
      transform: translate3d(0, 66px, 0);
    }
  }
  .nav-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    .nav-bar-left-logo-1 {
      cursor: pointer;
      display: flex;
      align-items: center;
      .logo-img {
        transition: all 0.3s;
        width: 125px;
        // aspect-ratio: 141 / 40;
        aspect-ratio: 125 / 37.5;
        color: #000;
        display: flex;
        flex-direction: row;
        align-items: center;
        .logo-text {
          color: inherit;
          transition: inherit;
        }
        .logo-transparent {
          color: transparent;
        }
      }
    }
    @include device-max-width-600 {
      .nav-bar-left-logo-1 {
        .logo-img {
          width: 110px;
        }
      }
    }
    .nav-bar-center-link {
      display: flex;
      align-items: center;
      .t-e-switch-language {
        margin-right: 30px;
      }
      @include device-max-width-375 {
        .t-e-switch-language {
          margin-right: 5px;
        }
      }
      .link-content {
        display: flex;
        align-items: center;
        .hot-flag {
          display: flex;
          position: absolute;
          right: -20px;
          top: -20px;
          filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.16)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
          img {
            width: 28px;
            aspect-ratio: 1 / 1;
          }
        }
      }
      // 特殊处理
      @include device-max-width-custom(1100px) {
        .link-content {
          display: none;
        }
      }
      .login-in {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #fff;
        height: 40px;
        cursor: pointer;
        background: #000000;
        border-radius: 1000px;
        font-size: 16px;
        padding: 12px 16px;
        transition: all 0.3s;
        @include ease-in-out;
        .scroll-text {
          padding: 0 16px;
        }
        &:hover {
          background: #fee065;
          color: #000;
        }
      }
      @include device-max-width-600 {
        .login-in {
          // width: 66px;
          height: 30px;
          font-size: 12px;
        }
      }
      .login-avatar {
        width: 40px;
        height: 40px;
        background-color: $avatar-bg-color;
        border-radius: 50%;
        position: relative;
        @include ease-in-out;
        cursor: pointer;
        .el-dropdown {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          outline: 0;
          .el-tooltip__trigger {
            width: 100%;
            height: 100%;
          }
        }
        .dropdown-detail {
          color: red;
        }
        .avatar-name {
          color: #fff;
          font-weight: 700;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          @include fontSemibold;
          &:hover,
          &:active {
            outline: none;
          }
          &-c {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }

      @include device-max-width-900 {
        .login-avatar {
          width: 30px;
          height: 30px;
        }
      }
    }
    .nav-bar-right-button {
      display: none;
      margin-left: 12px;
      width: 32px;
      height: 32px;
      position: relative;
      cursor: pointer;
      @include ease-in-out;
      > span {
        left: 50%;
        transform: translateX(-50%);
        top: 14px;
        height: 2px;
        width: 16px;
        position: absolute;
        background-color: #000;
        transition: all 0.2s linear;
      }
      &::before {
        left: 50%;
        transform: translateX(-50%);
        width: 16px;
        height: 2px;
        content: "";
        top: 8px;
        position: absolute;
        background-color: #000;
        transition: all 0.2s linear;
      }
      &::after {
        left: 50%;
        transform: translateX(-50%);
        width: 16px;
        height: 2px;
        content: "";
        top: 20px;
        position: absolute;
        background-color: #000;
        transition: all 0.2s linear;
      }
    }
    // 特殊处理
    @include device-max-width-custom(1100px) {
      .nav-bar-right-button {
        display: block;
      }
    }
  }

  .hide-menu-list {
    position: fixed;
    z-index: 1000;
    right: 0;
    top: 64px;
    width: 100%;
    height: calc(100vh - 64px);
    transform: translate3d(100%, 0, 0);
    background-color: transparent;
    opacity: 0;
    @include ease-in-out;
    overflow: auto;
    .hide-menu-list-container {
      padding: 32px 0 32px 48px;
      background-color: #fff;
      border-radius: 0px 0px 24px 24px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, 0.05);
    }
  }
  @include device-max-width-900 {
    .hide-menu-list {
      top: 62px;
      height: calc(100vh - 62px);
    }
  }
}

.dark-nav {
  .nav-bar-container {
    .nav-bar-left-logo-1 {
      .logo-img {
        color: #fff;
      }
    }

    .nav-bar-center-link {
      .t-e-switch-language {
        .t-e-switch-language-c {
          .c-text {
            color: #fff;
          }
          .el-icon {
            color: #fff;
          }
          .lg {
            color: #fff;
          }
        }
      }
      .nav-bar-right-button {
        &::before {
          background-color: #fff;
        }
        &::after {
          background-color: #fff;
        }
        span {
          background-color: #fff;
        }
      }
      .link-content {
        .navbar-menu-list-warpper {
          :deep(.navbar-menu-list-container) {
            .list-link-item {
              color: #fff;
              .el-dropdown {
                .el-dropdown-link {
                  color: #fff;
                }
              }
              a {
                color: #fff;
              }
            }
          }
        }
      }
      .login-in {
        background: #fff;
        color: #000;
        transition: all 0.5s;
        transition-timing-function: cubic-bezier(0, 1.21, 0, 1.01);
      }
    }
  }
}

@include device-max-width-1400 {
  .home-e-nav-bar {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@include device-max-width-900 {
  .home-e-nav-bar {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.nav-bar_active {
  background-color: #fff !important;
  .hide-menu-list {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    .hide-menu-list-container {
      opacity: 1;
    }
  }
  .nav-bar-container {
    .nav-bar-right-button {
      > span {
        opacity: 0;
      }
      &::before {
        width: 20px;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        width: 20px;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.dark-nav {
    background-color: #000 !important;

    :deep(.hide-menu-list) {
      .hide-menu-list-container {
        background-color: #000;
        .navbar-menu-list {
          .list-link-item {
            color: #fff;
            a {
              color: #fff;
            }
          }
          .arrow {
            color: #fff;
          }
        }
      }
    }
  }
}
.el-popper.el-dropdown__popper.nav-drop-down_zz {
  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  border: none;
  .el-dropdown-menu__item:not(.is-disabled):focus {
    color: $bg-color;
    background-color: rgba(0, 0, 0, 0.55);
  }
  .el-popper__arrow {
    display: none;
  }
  .el-dropdown-menu {
    border-radius: 16px;
    padding: 16px;
    width: 232px;
    .dropdown-detail {
      display: flex;
      flex-direction: column;
      .top-avatar {
        display: flex;
        align-items: center;
        position: relative;
        .top-avatar-img {
          width: 41px;
          height: 41px;
          border-radius: 50%;
          flex-shrink: 0;
          background-color: $avatar-bg-color;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #fff;
          font-weight: 700;
          @include fontSemibold;
        }
        .top-avatar-d {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 8px;
          word-break: break-word;
          overflow: hidden;
          .top-avatar-nickName {
            color: rgba(0, 0, 0, 0.85);
            font-size: 18px;
            line-height: 21px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .top-avatar-email {
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.55);
            margin-top: 4px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
      .credits {
        margin-top: 17px;
        display: flex;
        flex-direction: column;
        background-color: #f6f7fb;
        padding: 18px 16px 16px 16px;
        margin-bottom: 30px;
        border-radius: 12px;
        .credits-i {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 17px;
          .credits-i-left-c {
            display: flex;
            align-items: center;
            span {
              font-size: 16px;
              line-height: 19px;
              color: #000000;
              margin-left: 6px;
            }
          }
          .credits-i-right-i {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: space-between;
            font-size: 16px;
            line-height: 19px;
            margin-left: 8px;
            &.credits-i-right-i_cursor {
              cursor: pointer;
            }
            i {
              color: rgba(0, 0, 0, 0.55);
              font-size: 14px;
            }
          }
        }
        .credits-t {
          display: flex;
          > div {
            display: flex;
            flex-direction: column;
            width: 50%;
            .title {
              font-size: 11px;
              line-height: 13px;
              color: rgba(0, 0, 0, 0.55);
            }
            .count {
              font-size: 16px;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.85);
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
        .credits-t-t {
          .used-content {
            display: flex;
            align-items: center;
            > span.c {
              color: #000;
              font-size: 16px;
              margin-right: 6px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            > span.u {
              color: rgba(0, 0, 0, 0.55);
              font-size: 14px;
            }
          }
        }
        .credits-d {
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: rgba(0, 0, 0, 0.55);
          margin-top: 14px;
        }
      }
      .menu-item {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 14px;
        &.menu-item_mar {
          margin-top: 30px;
        }
        .menu-item_i {
          margin-bottom: 29px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .menu-item_c {
            flex: 1;
            margin-left: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
              .el-icon {
                color: #1877f2;
                font-weight: 700;
              }
              span {
                color: #1877f2;
              }
              svg {
                g {
                  stroke: #1877f2;
                }
              }
            }
          }

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.nav-shadow {
  box-shadow: 0px 16px 36px 0px rgba(0, 0, 0, 0.05);
}
</style>
